<template>
  <!-- eslint-disable -->
  <CodeGenerator v-if="store.state.username" v-model:code="myCode" />

  <div class="connect-to-other">
    <div>Or connect to other person</div>
    <Input placeholder="Code" textAlign="center" v-model:value="otherCode" :disabled="checkingCode" />
    <div class="connection-error">{{ connectError }}</div>
    <button v-if="otherCode" @click="connectToOtherClicked" :disabled="checkingCode">Connect</button>
    <div v-if="checkingCode">Trying to connect ({{ codeTime }}) . . .</div>
  </div>

  <Popup :show="showConnectionReceived">
    <div>{{ connectionReceivedFrom }} is trying to connect</div>
    <div>It will auto deny in {{ store.state.defaultConnectTime }}s.</div>
    <div style="text-align: center">Please make sure that you're allowing connection to trusted person only.</div>
    <button @click="answerConnection(true)">Allow</button>
    <button class="danger-button" @click="answerConnection(false)">Deny ({{ codeTime }})</button>
  </Popup>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import CodeGenerator from '../components/CodeGenerator.vue';
import Input from '../components/Input.vue';
import Popup from '../components/Popup.vue';

//#region region Data
const myCode = ref('');
const otherCode = ref('');
const checkingCode = ref(false);
const connectError = ref('');
const showConnectionReceived = ref(false);
const connectionReceivedFrom = ref('');

const codeResolve = ref(null);
const codeTimeout = ref(null);
const codeTimer = ref(null);
const codeTime = ref(0);

const store = useStore();
const router = useRouter();
//#endregion region Data

//#region Methods
const connectToOtherClicked = async () => {
  connectError.value = '';

  if (otherCode.value == myCode.value) {
    connectError.value = 'You cannot connect to your own code';
  } else {
    store.state.hub.invoke('CheckCode', otherCode.value, store.state.username);

    checkingCode.value = true;
    let connectionDetails = await checkingForCode(); // Will return a value if the code is okay and accepted
    checkingCode.value = false;

    if (connectionDetails) { // Connection successful
      localStorage.setItem('connectedTo', connectionDetails.receiver);
      localStorage.setItem('connectionId', connectionDetails.connectionId);
      localStorage.setItem('connectionExpired', connectionDetails.connectionExpired);
      router.push('/Transfer');
    } else {
      connectError.value = 'The other person has deny or you enter a wrong code.';
    }
  }
}
const answerConnection = (allowOrDeny) => {
  showConnectionReceived.value = false;

  if (allowOrDeny) { // Allow the connection
    let date = new Date();
    let invokeObject = {
      requestor: connectionReceivedFrom.value,
      receiver: store.state.username,
      code: myCode.value,
      connectionExpired: new Date(date.getTime() + store.state.defaultConnectionExpired * 60 * 1000)
    };
    store.state.hub.invoke('ConnectionAllowed', invokeObject);

    localStorage.setItem('connectedTo', connectionReceivedFrom.value);
    localStorage.setItem('connectionId', `${connectionReceivedFrom.value}${myCode.value}${store.state.username}`);
    localStorage.setItem('connectionExpired', invokeObject.connectionExpired);
    router.push('/Transfer');
  } else { // Deny the connection
    store.state.hub.invoke('ConnectionAllowed', null);
  }

  if (codeTimer.value) {
    clearInterval(codeTimer.value);
  }
  if (codeTimeout.value) {
    clearTimeout(codeTimeout.value);
  }
}
const checkingForCode = () => {
  let prom = new Promise(r => codeResolve.value = r);
  codeTimeout.value = setTimeout(() => {
    codeResolve.value(null);
    if (codeTimer.value) {
      clearInterval(codeTimer.value);
    }
  }, store.state.defaultConnectTime * 1000);

  // Get the default connecting time from store
  codeTime.value = store.state.defaultConnectTime;
  // Set the interval of the timer for every second
  codeTimer.value = setInterval(() => {
    codeTime.value--;
  }, 1000);
  return prom;
}
//#endregion Methods

//#region Hub Methods
const codeReceived = (code, name) => {
  if (myCode.value == code) {
    connectionReceivedFrom.value = name;
    showConnectionReceived.value = true;

    // Show the connect timeout countdown
    codeTime.value = store.state.defaultConnectTime;
    codeTimer.value = setInterval(() => {
      codeTime.value--;
    }, 1000);

    // Set the timeout based on the global timeout
    codeTimeout.value = setTimeout(() => {
      showConnectionReceived.value = false;
      if (codeTimer.value) {
        clearInterval(codeTimer.value);
      }
    }, store.state.defaultConnectTime * 1000);
  }
}
const connectionAllowed = (connectionModel) => {
  codeResolve.value(connectionModel);
  if (codeTimer.value) {
    clearInterval(codeTimer.value);
  }
  if (codeTimeout.value) {
    clearTimeout(codeTimeout.value);
  }
}
//#endregion Hub Methods

onMounted(() => {
  // Checking if there the user already connected to someone
  let connectedTo = localStorage.getItem('connectedTo') || '';
  if (connectedTo) {
    store.state.connectedTo = connectedTo;
    router.push('/Transfer');
    return;
  }

  // When someone is trying to connect with code and the name of the person who tries to connect
  store.state.hub.on('ReceivedCheckCode', codeReceived);

  // When receiving that code exists
  store.state.hub.on('ReceivedConnectionAllowed', connectionAllowed);
})
</script>

<style scoped>
.connection-error {
  color: indianred;
}
.connect-to-other {
  width: 90%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  font-size: 1.1em;
  padding-bottom: 20px;
}
.connect-to-other > :not(:first-child) {
  margin-top: 10px;
}
</style>